import React from "react";
import { Link } from "react-router-dom";

export const PostoCard = ({ posto }) => {
    return (
        <div className="card m-3" style={{ width: '18rem' }}>
            <div className="card-body">
                <div className="row">
                    <h5 className="card-title">{posto.nome_fantasia}</h5>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <span className="card-text">Senha desbloqueio: {posto.senha_manual}</span>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <span className="card-text">Numero de Box: {posto.numero_box}</span>
                    </div>
                </div>
                <Link to={`/posto/${posto.id}`} className="btn btn-primary">Detalhes</Link>
            </div>
        </div >)
}