import React, { useEffect, useState } from "react"
import { useUser } from "../context/UserProvider"
import { Link } from "react-router-dom";


export default function Navbar() {
    const { logout, userData } = useUser();
    const [menuOpen, setMenuOpen] = useState(localStorage.getItem('sidebar-open') === 'true');
    function toggleMenu() {
        localStorage.setItem('sidebar-open', !menuOpen);
        setMenuOpen(!menuOpen);
    }
    useEffect(() => {
        if (menuOpen)
            document.body.classList.remove('sidebar-collapse');
        else
            document.body.classList.add('sidebar-collapse');
    }, [menuOpen])
    return (<>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <div className="nav-link" data-widget="pushmenu" role="button" onClick={toggleMenu}><i className="fas fa-bars"></i></div>
                </li>
            </ul>
        </nav>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <div className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <img src={`${process.env.PUBLIC_URL}/logo192.png`} className="img-circle elevation-2" alt="User" />
                            </div>
                            <div className="info">
                                <div href="#" className="d-block text-white">{userData?.user?.name}</div>
                            </div>
                        </div>
                        <li className="nav-item">
                            <Link to={`/`} className="nav-link">
                                <i className="nav-icon fas fa-chart-bar"></i>
                                <p>
                                    Dashboard
                                </p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={`/export`} className="nav-link">
                                <i className="nav-icon fas fa-file-export"></i>
                                <p>
                                    Exportar Dados
                                </p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <div href="#" onClick={logout} className="nav-link" style={{ color: '#c2c7d0', cursor: 'pointer' }}>
                                <i className="nav-icon fas fa-door-closed"></i>
                                <p>
                                    Sair
                                </p>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    </>)
}