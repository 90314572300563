import { useEffect, useState } from 'react';

import { postos as postosAPI } from '../../services/api'
import { PostoCard } from '../../components/PostoCard';
import { useUser } from "../../context/UserProvider";
import ActivityIndicator from '../../components/ActivityIndicator';

export default function Home() {
    const { setIsLogged } = useUser();
    const [postos, setPostos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function loadPostos() {
        try {
            const resp = await postosAPI();
            setPostos(resp.data);
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('@bathuserdata');
                setIsLogged(false);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadPostos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className='content'>
            <div className='row'>
                {isLoading && (
                    <div className='col-12 justify-content-center d-flex mt-5'>
                        <ActivityIndicator show={isLoading} />
                    </div>
                )}
                {postos.map((posto, index) => (
                    <PostoCard posto={posto} key={index} />
                ))}
            </div>
        </div>)
}