import React from "react";


import UserProvider from './context/UserProvider'
import Routes from './routes'
function App() {
  return (
    <UserProvider>
      <Routes />
    </UserProvider>
  );
}

export default App;
