import React from "react"
import styled from "styled-components"
export default function ActivityIndicator({ show }) {
    if (!!show) {
        return (
            <ActivityIndicatorStyle></ActivityIndicatorStyle>
        )
    }
    return null
}

export const ActivityIndicatorStyle = styled.div`
    width: 48px;
    height: 48px;
    border: 5px solid #DADADA;
    border-bottom-color: #007bff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`


