import React, { useState } from "react"
import { LoadButton } from "../../components/LoadButton";
import { useUser } from "../../context/UserProvider";
import { login, recoverPassword } from "../../services/api";
import "./styles.css";
import logo from '../../assets/img/logo.svg';
import banner from '../../assets/img/banner.png';

export default function Login() {
    const { setUser } = useUser();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [invalid, setInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showLogin, setShowLogin] = useState(1);
    const [errorMsg, setErrorMsg] = useState("");
    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const res = await login({
                username: email, password
            });
            setUser(res.data);
        }
        catch (error) {
            setInvalid(error.response.status === 401);
        }
        setIsLoading(false);
    }

    async function recoverPasswordEvent() {
        setIsLoading(true);
        try {
            const res = await recoverPassword(email);
            setErrorMsg(res.data.msg);
        } catch (err) {
            console.log(err.response);
            setErrorMsg(err.response.data.message);
            setShowLogin(3);

        }
        setShowLogin(3);
        setIsLoading(false);
    }

    return (
        <div className="Login h-100" style={{ backgroundImage: 'url(' + banner + ')' }} alt="logo">
            {showLogin === 1 &&
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12 text-center mb-3">
                            <img src={logo} width={252} className="img-fluid" alt="Logo" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email/Usuário</label>
                        <input
                            id="email"
                            className="form-control"
                            autoFocus
                            value={email}
                            placeholder="Digite seu email ou usuário"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group d-flex flex-column">
                        <label htmlFor="password">Senha</label>
                        <input
                            id="password"
                            className="form-control"
                            type="password"
                            placeholder="Senha"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="text-right link" onClick={() => setShowLogin(2)}>Esqueceu a senha?</span>
                    </div>
                    <LoadButton className="btn-primary" type="submit" disabled={!validateForm()} isLoading={isLoading}>
                        ENTRAR
                    </LoadButton>
                    {invalid &&
                        <div className="alert alert-danger mt-3" role="alert">
                            Usuário/Senha inválidos
                        </div>
                    }
                </form>
            }{
                showLogin === 2 &&
                <form><div className="row">
                    <div className="col-12 text-center mb-3">
                        <img src={process.env.PUBLIC_URL + "/logo192.png"} width={52} className="img-fluid" alt="logo" />
                    </div>
                </div>
                    <span>Digite seu email cadastrado para recuperar sua senha.</span>
                    <div className="form-group">
                        <label>Email/Usuário</label>
                        <input
                            className="form-control"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <LoadButton className="btn-primary" type="button" onClick={recoverPasswordEvent} isLoading={isLoading}>
                        AVANÇAR
                    </LoadButton>
                </form>

            }{
                showLogin === 3 &&
                <form><div className="row">
                    <div className="col-12 text-center mb-3">
                        <img src={process.env.PUBLIC_URL + "/logo192.png"} width={52} className="img-fluid" alt="logo" />
                    </div>
                </div>
                    <div className="mb-5">
                        <span>{errorMsg}</span>
                    </div>
                    <LoadButton style={{ marginTop: 5 }} className="btn-primary" type="button" onClick={() => setShowLogin(1)} isLoading={false}>
                        VOLTAR
                    </LoadButton>
                </form>
            }

        </div>)
}