import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom";
import { getBanhos } from "../../services/api";
import PostoShow from "../../components/Posto/PostoShow";
import ActivityIndicator from "../../components/ActivityIndicator";
// import { PieChart } from "../../components/Chart/Pie";

export default function Posto() {
    const { id } = useParams();
    const [banhosData, setBanhosData] = useState({});
    const [postoData, setPostoData] = useState({
        name: ''
    });
    const [isLoading, setIsLoading] = useState(true);
    async function fetchData() {
        try {
            const { data: { name, numero_box, ...response } } = await getBanhos(id, window.location.search);
            setPostoData({
                name,
                numero_box
            })
            setBanhosData(response);
        } catch (err) {
            if (err.response.status === 404 || err.response.status === 401) {
                window.location = process.env.PUBLIC_URL + '/';
            }
        }
        setIsLoading(false);
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (<>
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">{postoData.name}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body justify-content-center d-flex">
                        <ActivityIndicator show={isLoading} />
                        {!isLoading && (
                            <div className="col-12">
                                <PostoShow banhosData={banhosData} postoData={postoData} fetchData={fetchData} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </>)
}