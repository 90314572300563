import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { BarChart } from '../../components/Chart/Bar'
import { LoadButton } from "../LoadButton";

export default function PostoShow({ banhosData, postoData, fetchData }) {
    let [, setSearchParams] = useSearchParams();
    const [periodoTotal, setPeriodoTotal] = useState({ pago: 0, cortesia: 0, api: 0 })
    const [chartData, setChardData] = useState(false);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const [filterLoading, setFilterLoading] = useState(false);
    const [removeFilterLoading, setRemoveFilterLoading] = useState(false);
    useEffect(() => {
        if (banhosData.totalizador) {
            const periodo_total = { pago: 0, cortesia: 0, api: 0 }
            periodo_total.pago = banhosData.pagos?.reduce((total, val) => total + val, 0);
            periodo_total.cortesia = banhosData.cortesias?.reduce((total, val) => total + val, 0);
            periodo_total.api = banhosData.api?.reduce((total, val) => total + val, 0);
            setPeriodoTotal({
                pago: periodo_total.pago,
                cortesia: periodo_total.cortesia,
                api: periodo_total.api,
            })
            setStartDate(moment.unix(banhosData.startDate).format("YYYY-MM-DD"));
            setEndDate(moment.unix(banhosData.endDate).format("YYYY-MM-DD"));

            const dataset = [{
                label: 'Cortesia',
                data: banhosData.cortesias,
                backgroundColor: [
                    '#2f3293'
                ],
                borderColor: [
                    '#2f3293'
                ],
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
            },
            {
                label: 'Pagos',
                data: banhosData.pagos,
                backgroundColor: [
                    '#94c51e'
                ],
                borderColor: [
                    '#94c51e'
                ],
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
            }]
            if (periodo_total.api > 0)
                dataset.push({
                    label: 'App',
                    data: banhosData.api,
                    backgroundColor: [
                        '#fdcf00'
                    ],
                    borderColor: [
                        '#fdcf00'
                    ],
                    borderWidth: 1,
                    cubicInterpolationMode: 'monotone',
                });
            setChardData({
                labels: banhosData.labels,
                datasets: dataset
            });

            // setMediaChart({
            //     pagos: (periodo_total.pago / banhosData.labels.length).toFixed(0),
            //     cortesias: (periodo_total.cortesia / banhosData.labels.length).toFixed(0),
            //     total: ((periodo_total.pago + periodo_total.cortesia) / banhosData.labels.length).toFixed(0),
            // })
        }
    }, [banhosData]);

    async function applyFilter(e) {
        setFilterLoading(true);
        e.preventDefault();
        setSearchParams({ start_date: startDate, end_date: endDate });
        await fetchData();
        setFilterLoading(false);
    }

    async function removeFilter() {
        setRemoveFilterLoading(true);
        setSearchParams({});
        await fetchData();
        setRemoveFilterLoading(false);
    }
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    {banhosData.lastUpdate && <span>Última atualização: {moment.unix(banhosData.lastUpdate).format("DD/MM/YYYY HH:mm")}</span>}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    Número de Box: {postoData.numero_box}
                </div>
            </div>
            <hr />
            <div>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">Totalizador de banhos</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className={`col-sm-${(banhosData.totalizador?.api ?? 0) > 0 ? '3' : '4'} col-12`}>
                                <div className="description-block border-right">
                                    <h5 className="description-header"><span id="total_total" style={{ fontSize: '2.7em' }}>{((banhosData.totalizador?.pago ?? 0) + (banhosData.totalizador?.cortesia ?? 0) + (banhosData.totalizador?.api ?? 0) ?? 0).toLocaleString()}</span></h5>
                                    <span className="description-text">BANHOS</span>
                                </div>
                            </div>
                            <div className={`col-sm-${(banhosData.totalizador?.api ?? 0) > 0 ? '3' : '4'} col-12`}>
                                <div className="description-block border-right">
                                    <h5 className="description-header"><span id="total_cortesia" style={{ fontSize: '2.7em' }}>{(banhosData.totalizador?.cortesia ?? 0).toLocaleString()}</span></h5>
                                    <span className="description-text">CORTESIAS</span>
                                </div>
                            </div>
                            <div className={`col-sm-${(banhosData.totalizador?.api ?? 0) > 0 ? '3' : '4'} col-12`}>
                                <div className="description-block border-right">
                                    <h5 className="description-header"><span id="total_pago" style={{ fontSize: '2.7em' }}>{(banhosData.totalizador?.pago ?? 0).toLocaleString()}</span></h5>
                                    <span className="description-text">PAGOS</span>
                                </div>
                            </div>{
                                banhosData.totalizador?.api > 0 && (
                                    <div className={`col-sm-${(banhosData.totalizador?.api ?? 0) > 0 ? '3' : '4'} col-12`}>
                                        <div className="description-block">
                                            <h5 className="description-header"><span id="total_pago" style={{ fontSize: '2.7em' }}>{(banhosData.totalizador?.api ?? 0).toLocaleString()}</span></h5>
                                            <span className="description-text">App</span>
                                        </div>
                                    </div>)}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">Estatísticas</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <form onSubmit={applyFilter}>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label>Data inicial:</label>
                                                <div className="input-group date">
                                                    <input id="start_date" className="form-control" name="start_date" value={startDate} type="date" onChange={e => setStartDate(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label>Data final:</label>
                                                <div className="input-group date">
                                                    <input id="end_date" className="form-control" name="end_date" value={endDate} type="date" onChange={e => setEndDate(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>Filtro</label>
                                                <div className="input-group date" data-target-input="nearest">
                                                    <LoadButton isLoading={filterLoading} className="btn btn-primary">Aplicar filtro</LoadButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>Filtro</label>
                                                <div className="input-group date" data-target-input="nearest">
                                                    <LoadButton isLoading={removeFilterLoading} className="btn btn-success" onClick={removeFilter}>Remover filtro</LoadButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <h6>Dados no período de <span id="range_date"><b>{moment.unix(banhosData.startDate).format("DD/MM/YYYY HH:mm")}</b> até <b>{moment.unix(banhosData.endDate).format("DD/MM/YYYY HH:mm")}</b></span></h6>
                                <div className="row justify-content-center">
                                    <div className=" col-md-8 col-sm-12">
                                        <BarChart chartData={chartData} />
                                    </div>
                                    <div className="d-flex align-items-center border-left justify-content-center col-md-2 col-sm-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="description-block">
                                                            <h5 className="description-header"><span id="periodo_total" style={{ fontSize: '2.7em' }}>{(periodoTotal.api + periodoTotal.pago + periodoTotal.cortesia).toLocaleString()}</span></h5>
                                                            <span className="description-text">BANHOS</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="description-block">
                                                            <h5 className="description-header"><span id="periodo_cortesia" style={{ fontSize: '2.7em' }}>{periodoTotal.cortesia.toLocaleString()}</span></h5>
                                                            <span className="description-text">CORTESIAS</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="description-block">
                                                            <h5 className="description-header"><span id="periodo_pago" style={{ fontSize: '2.7em' }}>{periodoTotal.pago.toLocaleString()}</span></h5>
                                                            <span className="description-text">PAGOS</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {periodoTotal.api > 0 && (
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="description-block">
                                                                <h5 className="description-header"><span id="periodo_pago" style={{ fontSize: '2.7em' }}>{periodoTotal.api.toLocaleString()}</span></h5>
                                                                <span className="description-text">App</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}