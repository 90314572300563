import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from './pages/Home'
import Navbar from "./components/Navbar";
import Posto from "./pages/Posto";
import Recover from "./pages/Recover";
import Login from "./pages/Login";
import { useUser } from "./context/UserProvider";
import ActivityIndicator from "./components/ActivityIndicator";
import ExportPage from "./pages/ExportPage";

export default function AppRoutes() {
    const { isLogged, isLoading } = useUser();
    if (isLoading)
        return (
            <div className="wrapper d-flex justify-content-center align-items-center">
                <ActivityIndicator show={true} />
            </div>
        )
    return (isLogged ?
        <>
            <Router >

                <div className="wrapper">
                    <Navbar />
                    <div className="content-wrapper">
                        <div className="container-fluid">
                            <Routes>
                                <Route exact path="/" element={<Home />} />
                                <Route path="/posto/:id" element={<Posto />} />
                                <Route path="/export" element={<ExportPage />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </Router>
        </> : <Router>
            <Routes>
                <Route path="/password/reset/:token&email=:email" element={<Recover />} />
                <Route path="*" element={<Login />} />
            </Routes>
        </Router>)
}
