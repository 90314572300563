import React, { useState } from "react"
import { LoadButton } from "../../components/LoadButton";
import { changePassword } from "../../services/api";
import "./styles.css";
import banner from '../../assets/img/banner.png';
import { useParams, useNavigate } from "react-router-dom";

export default function Recover(props) {
    const navigate = useNavigate();
    const { token, email } = useParams();
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setMessage] = useState(false)

    function validateForm() {
        return (password.length > 3) && (password === password_confirmation);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await changePassword({
                email,
                password, confirm_password: password_confirmation, token
            });
            setMessage(response.data.msg);
        }
        catch (error) {
            setMessage(error.response.data.message);
        }
        setIsLoading(false);
    }



    return (
        <div className="Login h-100" style={{ backgroundImage: 'url(' + banner + ')' }}>
            {!responseMessage ? <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-12 text-center mb-3">
                        <img src={process.env.PUBLIC_URL + "/logo192.png"} width={52} className="img-fluid" alt="logo" />
                    </div>
                </div>
                <span>Você está a apenas um passo de sua nova senha, recupere sua senha agora.</span>
                <div className="form-group d-flex flex-column">
                    <label>Senha</label>
                    <input
                        className="form-control"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>  <div className="form-group d-flex flex-column">
                    <label>Confirme sua senha</label>
                    <input
                        className="form-control"
                        type="password"
                        value={password_confirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />
                </div>
                <LoadButton className="btn-primary" type="submit" disabled={!validateForm()} isLoading={isLoading}>
                    ENTRAR
                </LoadButton>
            </form> : <form><div className="row">
                <div className="col-12 text-center mb-3">
                    <img src={process.env.PUBLIC_URL + "/logo192.png"} width={52} className="img-fluid" alt="logo" />
                </div>
            </div>
                <div className="mb-5">
                    <span>{responseMessage}</span>
                </div>
                <LoadButton style={{ marginTop: 5 }} className="btn-primary" type="button" onClick={() => { navigate('/') }} isLoading={false}>
                    VOLTAR
                </LoadButton>
            </form>
            }


        </div>)
}