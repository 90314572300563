import axios from 'axios'

const api = axios.create({
    baseURL: 'https://pdb.evoautomacoes.com.br/'
})

function authHeader(user) {
    const userData = JSON.parse(localStorage.getItem("@bathuserdata"))
    return { Authorization: 'Bearer ' + userData?.access_token ?? '' }
}

export async function login({ username, password }) {
    return await api.post('auth/login', { username, password });
}

export async function logout() {
    return await api.get('auth/logout', { headers: authHeader() })
}

export async function get(url, options) {
    return await api.get(url, { headers: authHeader(), ...options })
}

export async function postos() {
    return await get('/postos');
}

export async function getBanhos(postoID, query = '') {
    return await get(`/banho/${postoID}${query}`);
}

export async function recoverPassword(username) {
    return await api.post('auth/recover-password', { email: username });

}

export async function changePassword(data) {
    return await api.post('auth/change-password', data);
}


export async function download_csv(postosID, dateRange) {

    return await get(`/download?postos=${postosID.join(",")}&date=${dateRange[0]},${dateRange[1]}`, { responseType: 'blob' });
}