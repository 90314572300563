import React, { useContext, createContext, useState, useEffect } from "react";
import { logout as apiLogout } from '../../services/api'

const UserContext = createContext({});
export default function UserProvider(props) {
    const [userData, setUserData] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const userDataStorage = JSON.parse(localStorage.getItem("@bathuserdata"));
        if (userDataStorage) {
            setUserData(userDataStorage);
            setIsLogged(true);
        } else {
            setIsLogged(false);
        }
        setIsLoading(false);
    }, []);

    function setUser(data) {
        setUserData(data);
        localStorage.setItem("@bathuserdata", JSON.stringify(data));
        setIsLogged(true);
    }

    async function logout() {
        await apiLogout();
        localStorage.removeItem("@bathuserdata");
        setUserData(false);
        setIsLogged(false);
        // navigate('/');
        window.location.replace('/');
    }

    return (
        <UserContext.Provider value={{ userData, isLogged, isLoading, setUser, logout, setIsLogged }}>
            {props.children}
        </UserContext.Provider>
    );
}

export const useUser = () => useContext(UserContext); 