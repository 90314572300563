import { useEffect, useState } from 'react';

import { download_csv, postos as postosAPI } from '../../services/api'
import { useUser } from "../../context/UserProvider";
import ActivityIndicator from '../../components/ActivityIndicator';
import SelectMulti from '../../components/SelectMulti';
import { LoadButton } from '../../components/LoadButton';
import moment from 'moment';

export default function Home() {
    const { setIsLogged } = useUser();
    const [postos, setPostos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'month').startOf('month').format('yyyy-MM-DD'));
    const [endDate, setEndDate] = useState(moment().subtract(1, 'month').endOf('month').format('yyyy-MM-DD'));
    const [selectedPostos, setSelectedPostos] = useState([]);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [message, setMessage] = useState(null);
    async function loadPostos() {
        try {
            const resp = await postosAPI();
            setPostos(resp.data);
            setSelectedPostos(resp.data.map(p => ({ value: p.id, label: p.nome_fantasia })));

        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('@bathuserdata');
                setIsLogged(false);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadPostos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function download() {
        try {
            setDownloadLoading(true);
            setMessage("Realizando o download. Isso pode demorar um pouco...")
            const response = await download_csv(selectedPostos.map(p => p.value), [startDate, endDate])
            const type = response.headers['content-type']
            const filename = response.headers['x-filename']
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
        }
        catch (e) {
            setMessage("Ocorreu um erro tente novamente")
        }
        setMessage(null)
        setDownloadLoading(false);

    }
    return (
        <div className='content'>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Exportar Dados</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        {isLoading && (
                            <div className='col-12 justify-content-center d-flex mt-5 mb-5'>
                                <ActivityIndicator show={isLoading} />
                            </div>
                        )}
                        {!isLoading && (
                            <div className="card-body">
                                <div className="row">
                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label>Postos</label>
                                            <SelectMulti options={postos.map(p => ({ value: p.id, label: p.nome_fantasia }))} value={selectedPostos} onChange={setSelectedPostos} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Salvar como</label>
                                            <select className="custom-select">
                                                <option value={"csv"}>CSV</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row" id="date-select">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Data inicial:</label>
                                                    <div className="input-group date">
                                                        <input id="start_date" className="form-control" name="start_date" value={startDate} type="date" onChange={e => setStartDate(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label>Data final:</label>
                                                    <div className="input-group date">
                                                        <input id="end_date" className="form-control" name="end_date" value={endDate} type="date" onChange={e => setEndDate(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <LoadButton className={"btn btn-success"} isLoading={downloadLoading} onClick={download}><i className='fas fa-download mr-2'></i>Exportar</LoadButton>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {message}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >)
}